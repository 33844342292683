import React, { useState } from "react";
import create_UUID from "../../utils/create_uuid";
import { emergencyOption_SeedData } from "../seedData";
import IEmergencyResponseType from "../types/emergencyResponseType";
import { ChildType } from "../types/screenOptionType";

export const DEFAULT_EMERGENCY_RESPONSE: IEmergencyResponseType = {
  id: "",
  rootScreenId: "",
  name: "",
  deleted: false,
  optionType: 0,
  rootId: "",
};

export default function useEmergencyResponseEntity(): ResponseEntityType {
  const [responses, setResponses] = useState<Array<IEmergencyResponseType>>(emergencyOption_SeedData);

  const _addResponses = (response: IEmergencyResponseType) => {
    setResponses([...responses, response]);
  };

  const _removeResponse = (responseId: string) => {
    const filtered_responses = responses.filter((sc) => sc.id !== responseId);
    setResponses(filtered_responses);
  };

  const fetchResponse = (responseId: string): IEmergencyResponseType => {
    const response = responses.find((sc) => sc.id === responseId);
    return response || DEFAULT_EMERGENCY_RESPONSE;
  };

  const fetchAllResponses = (): Array<IEmergencyResponseType> => {
    return responses.filter((u) => !u.deleted);
  };

  const createResponse = (name: string, rootScreenId: string, optionType: ChildType, rootId?: string): string => {
    let response = { ...DEFAULT_EMERGENCY_RESPONSE };
    response.id = create_UUID();
    response.rootScreenId = rootScreenId;
    response.optionType = optionType;
    response.rootId = rootId;
    response.name = name;
    _addResponses(response);
    return response.id;
  };

  const deleteResponse = (responseId: string) => {
    const newResponses = responses.map((response) => {
      if (response.id === responseId) {
        const updatedResponse: IEmergencyResponseType = {
          ...response,
          deleted: true,
        };

        return updatedResponse;
      }

      return response;
    });

    setResponses(newResponses);
  };

  const updateName = (responseId: string, name: string) => {
    const newResponses = responses.map((response) => {
      if (response.id === responseId) {
        const updatedResponse: IEmergencyResponseType = {
          ...response,
          name,
        };

        return updatedResponse;
      }

      return response;
    });

    setResponses(newResponses);
  };

  const getAll = () => responses;

  return {
    getAll,
    createResponse,
    deleteResponse,
    updateName,
    fetchResponse,
    fetchAllResponses,
  };
}

export type ResponseEntityType = {
  getAll: () => Array<IEmergencyResponseType>;
  createResponse: (name: string, rootScreenId: string, optionType: ChildType, rootId?: string) => string;
  deleteResponse: (responseId: string) => void;
  updateName: (responseId: string, name: string) => void;
  fetchResponse: (responseId: string) => IEmergencyResponseType;
  fetchAllResponses: () => Array<IEmergencyResponseType>;
};
