import testData from "./testData";
import IEmergencyResponseType from "./types/emergencyResponseType";
import IScreenOptionType from "./types/screenOptionType";
import IScreenType from "./types/screenType";
import IStoryBlockType from "./types/storyBlockType";
import IStoryTreeType from "./types/storyTreeType";
import IUserType from "./types/userType";

export const users_SeedData: Array<IUserType> = testData.users;

export const storyBlocks_SeedData: Array<IStoryBlockType> = testData.storyBlocks;

export const storyTrees_SeedData: Array<IStoryTreeType> = testData.storyTree;

export const screenOptions_SeedData: Array<IScreenOptionType> = testData.screenOptions;

export const screens_SeedData: Array<IScreenType> = testData.screens;

export const emergencyOption_SeedData: Array<IEmergencyResponseType> = testData.emergencyResponse;
