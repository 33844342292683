import React, { useState } from "react";
import create_UUID from "../../utils/create_uuid";
import { screenOptions_SeedData } from "../seedData";
import IScreenOptionType from "../types/screenOptionType";

export const DEFAULT_OPTION: IScreenOptionType = {
  id: "",
  name: "",
  parentScreenId: "",
  childScreenId: "",
  childType: 0,
  storyBlockId: "",
};

export default function useScreenOptionEntity(): ScreenOptionEntityType {
  const [options, setOptions] = useState<IScreenOptionType[]>(screenOptions_SeedData);

  const _addOption = (option: IScreenOptionType) => {
    setOptions([...options, option]);
  };

  const _removeOption = (optionId: string) => {
    const filtered_options = options.filter((o) => o.id !== optionId);
    setOptions(filtered_options);
  };

  const createOption = (
    name: string,
    parentScreenId: string,
    childScreenId: string,
    childType: number,
    storyBlockId?: string
  ): string => {
    let option = { ...DEFAULT_OPTION };
    option.id = create_UUID();
    option.name = name;
    option.parentScreenId = parentScreenId;
    option.childScreenId = childScreenId;
    option.childType = childType;
    option.storyBlockId = storyBlockId;
    _addOption(option);
    return option.id;
  };

  const deleteOption = (id: string) => {
    _removeOption(id);
  };

  const updateName = (optionId: string, name: string) => {
    const newList = options.map((item) => {
      if (item.id === optionId) {
        const updatedItem: IScreenOptionType = {
          ...item,
          name,
        };

        return updatedItem;
      }

      return item;
    });

    setOptions(newList);
  };

  const fetchScreenOption = (optionId: string): IScreenOptionType => {
    const screen = options.find((o) => o.id === optionId);
    return screen || DEFAULT_OPTION;
  };

  const fetchScreenOptionBatch = (optionIds: Array<string>): Array<IScreenOptionType> => {
    const filtered_options = options.filter((o) => optionIds.includes(o.id));
    return filtered_options || [];
  };

  const getAll = () => options;

  return { getAll, createOption, deleteOption, updateName, fetchScreenOption, fetchScreenOptionBatch };
}

export type ScreenOptionEntityType = {
  getAll: () => Array<IScreenOptionType>;
  createOption: (
    name: string,
    parentScreenId: string,
    childScreenId: string,
    childType: number,
    storyBlockId?: string
  ) => string;
  deleteOption: (id: string) => void;
  updateName: (optionId: string, name: string) => void;
  fetchScreenOption: (optionId: string) => IScreenOptionType;
  fetchScreenOptionBatch: (optionIds: Array<string>) => Array<IScreenOptionType>;
};
