export const ALL_USERS = "/users";
export const USER = "/user/:userId";

export const PREVIEW_APP_PATH = USER + "/preview";

export const STORY_BLOCK_PATH = USER + "/storyblock/:storyBlockId";
export const STORY_TREE_PATH = USER + "/storytree/:storyTreeId";

export const PREVIEW_STORY_BLOCK_PATH = STORY_BLOCK_PATH + "/preview";
export const PREVIEW_STORY_TREE_PATH = STORY_TREE_PATH + "/preview";
