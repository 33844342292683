import React, { useState } from "react";
import create_UUID from "../../utils/create_uuid";
import distinct from "../../utils/distinct";
import { screens_SeedData } from "../seedData";
import IScreenData from "../types/screenDataType";
import IScreenType from "../types/screenType";

export const DEFAULT_SCREEN: IScreenType = {
  id: "",
  name: "",
  heading: "",
  text: "",
  optionIds: [],
  options: [],
  parentOptionId: "",
};

const getDefault = (): IScreenType => {
  return {
    id: "",
    name: "",
    heading: "",
    text: "",
    optionIds: [],
    options: [],
    parentOptionId: "",
  };
};

export default function useScreenEntity(): ScreenEntityType {
  const [screens, setScreens] = useState<IScreenType[]>(screens_SeedData);

  const _addScreen = (screen: IScreenType) => {
    setScreens((prevState) => {
      return [...prevState, screen];
    });
  };

  const _removeScreen = (screenId: string) => {
    setScreens((prevState) => {
      const filtered_screens = prevState.filter((sc) => sc.id !== screenId);
      return filtered_screens;
    });
  };

  const fetchScreen = (screenId: string): IScreenType => {
    const screen = screens.find((sc) => sc.id === screenId);
    return { ...(screen || DEFAULT_SCREEN) };
  };

  const createScreen = (name: string): string => {
    let screen = { ...DEFAULT_SCREEN };
    screen.id = create_UUID();
    screen.name = name;
    screen.optionIds = [];
    _addScreen(screen);
    return screen.id;
  };

  const deleteScreen = (id: string) => {
    _removeScreen(id);
  };

  const updateName = (screenId: string, name: string) => {
    setScreens((prevState) => {
      const newList = prevState.map((item) => {
        if (item.id === screenId) {
          const updatedItem: IScreenType = {
            ...item,
            name,
          };

          return updatedItem;
        }

        return item;
      });
      return newList;
    });
  };

  const updateHeading = (screenId: string, heading: string) => {
    const newList = screens.map((item) => {
      if (item.id === screenId) {
        const updatedItem: IScreenType = {
          ...item,
          heading,
        };

        return updatedItem;
      }

      return item;
    });

    setScreens(newList);
  };

  const updateText = (screenId: string, text: string) => {
    const newList = screens.map((item) => {
      if (item.id === screenId) {
        const updatedItem: IScreenType = {
          ...item,
          text,
        };

        return updatedItem;
      }

      return item;
    });

    setScreens(newList);
  };

  const updateScreenData = (screenId: string, data: IScreenData) => {
    const newList = screens.map((item) => {
      if (item.id === screenId) {
        const updatedItem: IScreenType = {
          ...item,
          ...data,
        };

        return updatedItem;
      }

      return item;
    });

    setScreens(newList);
  };

  const addOption = (screenId: string, optionId: string) => {
    setScreens((prevState) => {
      const newList = prevState.map((item) => {
        if (item.id === screenId) {
          item.optionIds?.push(optionId);
        }

        return item;
      });
      return newList.filter(distinct);
    });
  };

  const removeOption = (screenId: string, optionId: string) => {
    setScreens((prevState) => {
      const newList = prevState.map((item) => {
        if (item.id === screenId) {
          const updatedItem: IScreenType = {
            ...item,
            optionIds: item.optionIds?.filter((o) => o !== optionId),
          };

          return updatedItem;
        }

        return item;
      });
      return newList.filter(distinct);
    });
  };

  const addParentOption = (screenId: string, parentOptionId: string) => {
    setScreens((prevState) => {
      const newList = prevState.map((item) => {
        if (item.id === screenId) {
          item.parentOptionId = parentOptionId;
        }

        return item;
      });
      return newList.filter(distinct);
    });
  };

  const getAll = () => screens;

  return {
    getAll,
    createScreen,
    deleteScreen,
    updateName,
    updateHeading,
    updateText,
    addOption,
    removeOption,
    fetchScreen,
    addParentOption,
    updateScreenData,
  };
}

export type ScreenEntityType = {
  getAll: () => Array<IScreenType>;
  createScreen: (name: string) => string;
  deleteScreen: (screenId: string) => void;
  updateName: (screenId: string, name: string) => void;
  updateHeading: (screenId: string, heading: string) => void;
  updateText: (screenId: string, text: string) => void;
  addOption: (screenId: string, optionId: string) => void;
  removeOption: (screenId: string, optionId: string) => void;
  fetchScreen: (screenId: string) => IScreenType;
  addParentOption: (screenId: string, parentOptionId: string) => void;
  updateScreenData: (screenId: string, data: IScreenData) => void;
};
