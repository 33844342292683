import { IconButton } from "@material-ui/core";
import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

export default function PageWrapper({
  heading,
  children,
  disableBack = false,
}: {
  heading?: string;
  children: any;
  disableBack?: boolean;
}) {
  const { goBack } = useHistory();
  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          width: "calc(100vw - 70px)",
          height: 64,
          backgroundColor: "rgb(67 91 218)",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "0px 35px",
          color: "#fff",
        }}
      >
        {!disableBack && (
          <IconButton color="inherit" onClick={goBack}>
            <ArrowBackIcon></ArrowBackIcon>
          </IconButton>
        )}
        <p style={{ padding: 0, margin: 0, marginLeft: 15, marginBottom: 5 }}>{heading ?? "Header"}</p>
      </div>
      <div
        style={{
          width: "100%",
          flex: 1,
          backgroundColor: "#f1f1f1",
          overflow: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
}
