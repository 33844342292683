import React, { useState } from "react";
import { useDatabase } from "../data/DatabaseContext";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0px",
    margin: 10,
    height: 170,
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    margin: "auto",
  },
});

const AddNewCard = ({ add, children }: any) => {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [error, setError] = useState(false);

  const _addUser = () => {
    if (!name) {
      setError(true);
      return;
    }
    add(name);
    setName("");
  };

  const _updateName = (name: string) => {
    setError(false);
    setName(name);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {children}
        </Typography>
        <TextField
          onChange={(e) => _updateName(e.target.value)}
          value={name}
          error={error}
          helperText={error && "Please give a name"}
        ></TextField>
      </CardContent>
      <CardActions style={{ width: "100%" }}>
        <Button
          className={classes.button}
          onClick={() => _addUser()}
          style={{ width: "100%", margin: "0px 15px" }}
          size="large"
          variant="outlined"
        >
          Add
        </Button>
      </CardActions>
    </Card>
  );
};

export default AddNewCard;
