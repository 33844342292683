import React, { useState } from "react";
import PageWrapper from "./PageWrapper";
import { useDatabase } from "../data/DatabaseContext";
import IFullUserType from "../data/types/fullUserType";
import { useParams } from "react-router";
import PreviewApp from "../preview/Preview.App";

const UserPreviewPage = () => {
  const { userId, storyTreeId }: { userId: string; storyTreeId: string } = useParams();
  const { state, getFullUser } = useDatabase();
  const { user }: IFullUserType = getFullUser(userId);

  const heading: string = "User: " + user.name + " - preview";

  return (
    <PageWrapper heading={heading}>
      <div style={{ padding: 40, height: "calc(100% - 80px)", display: "flex", justifyContent: "center" }}>
        <PreviewApp rootScreenId={""}></PreviewApp>
      </div>
    </PageWrapper>
  );
};

export default UserPreviewPage;
