import React, { useEffect, useState } from "react";
import IUserType from "../data/types/userType";
import PageWrapper from "./PageWrapper";
import { useDatabase } from "../data/DatabaseContext";
import { useParams } from "react-router";
import IStoryTreeType from "../data/types/storyTreeType";
import ScreenMap from "../components/ScreenMap";
import IScreenType from "../data/types/screenType";
import ScreenEditor from "../components/ScreenEditor";

const StoryTreePage = () => {
  const { userId, storyTreeId }: { userId: string; storyTreeId: string } = useParams();
  const { getStoryTree, getUser, getScreen } = useDatabase();
  const user: IUserType = getUser(userId);
  const storyTree: IStoryTreeType = getStoryTree(storyTreeId);

  const heading: string = "User: " + user.name + " > Story Tree: " + storyTree.name + " - edit";

  const [currentScreen, setCurrentScreen] = useState(storyTree.rootScreenId);

  useEffect(() => {
    setCurrentScreen(storyTree.rootScreenId);
  }, [setCurrentScreen, storyTree.rootScreenId]);

  const changeCurrentScreen = (screenId: string) => {
    // console.log(screenId);
    setCurrentScreen(screenId);
  };

  return (
    <PageWrapper heading={heading}>
      <div style={{ display: "flex" }}>
        <ScreenMap
          rootScreenId={storyTree.rootScreenId}
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
        ></ScreenMap>
      </div>
    </PageWrapper>
  );
};

export default StoryTreePage;
