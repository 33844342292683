import React, { useEffect, useState } from "react";
import { useDatabase } from "../data/DatabaseContext";
import { makeStyles } from "@material-ui/core/styles";
import IScreenType from "../data/types/screenType";
import AddOptionDialog from "./AddOptionDialog";
import { ChildType } from "../data/types/screenOptionType";

import DeleteIcon from "@material-ui/icons/Delete";

import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import IScreenData from "../data/types/screenDataType";
import IconButton from "@material-ui/core/IconButton";
import SimpleAccordion from "./SimpleAccordion";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import "overlayscrollbars/css/OverlayScrollbars.css";
import ScreenEditor from "./ScreenEditor";
import PreviewButton from "../preview/Preview.Button";
import PreviewScreen from "../preview/Preview.Screen";
import { Button } from "@material-ui/core";
import { generatePath, Link, useParams } from "react-router-dom";
import { STORY_BLOCK_PATH } from "../Routes";

const useStyles = makeStyles({
  root: {
    width: 320,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px 0",
    background: "#fff",
    color: "#000",
    fontSize: 18,
    minHeight: "calc(100vh - 84px)",
    overflow: "auto",
  },
  options: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
});

const ScreenSidebar = ({
  screenId,
  children,
  setRequireUpdate,
  previewScreenId,
  changePreviewScreen,
  showPreview,
  toggleShowPreview,
  storyBlockId,
}: any) => {
  const classes = useStyles();

  const resetPreviewScreen = () => changePreviewScreen(screenId);

  return (
    <OverlayScrollbarsComponent options={{ scrollbars: { autoHide: "scroll" } }}>
      <div className={classes.root}>
        {!storyBlockId ? (
          <React.Fragment>
            {!!showPreview ? (
              <React.Fragment>
                <p
                  style={{
                    fontWeight: "bold",
                    fontFamily: "inherit",
                    fontSize: 18,
                    textAlign: "left",
                    paddingLeft: 20,
                    paddingBottom: 10,
                    borderBottom: "1px solid #000",
                  }}
                >
                  Preview
                </p>
                <PreviewScreen
                  screenId={previewScreenId}
                  interactive={true}
                  resetScreenId={resetPreviewScreen}
                  setScreenId={changePreviewScreen}
                ></PreviewScreen>
              </React.Fragment>
            ) : (
              <ScreenEditor screenId={screenId} setRequireUpdate={setRequireUpdate}></ScreenEditor>
            )}

            <PreviewButton preview={showPreview} togglePreview={toggleShowPreview}></PreviewButton>
          </React.Fragment>
        ) : (
          <StoryBlockPreview previewScreenId={previewScreenId} storyBlockId={storyBlockId}></StoryBlockPreview>
        )}
      </div>
    </OverlayScrollbarsComponent>
  );
};

export default ScreenSidebar;

function StoryBlockPreview({ previewScreenId, storyBlockId }: any) {
  const params: any = useParams();

  const [_previewScreenId, _setPreviewScreenId] = useState(previewScreenId);

  const _resetPreviewScreen = () => _setPreviewScreenId(previewScreenId);

  return (
    <React.Fragment>
      <p
        style={{
          fontWeight: "bold",
          fontFamily: "inherit",
          fontSize: 18,
          textAlign: "left",
          paddingLeft: 20,
          paddingBottom: 10,
          borderBottom: "1px solid #000",
        }}
      >
        Story Block Preview
      </p>
      <PreviewScreen
        screenId={_previewScreenId}
        interactive={true}
        resetScreenId={_resetPreviewScreen}
        setScreenId={_setPreviewScreenId}
      ></PreviewScreen>
      <Button
        component={Link}
        to={generatePath(STORY_BLOCK_PATH, { ...params, storyBlockId })}
        variant="outlined"
        color="primary"
      >
        Go to Story Block
      </Button>
    </React.Fragment>
  );
}
