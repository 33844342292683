import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import UserPage from "./pages/UserPage";
import AllUsersPage from "./pages/AllUsersPage";
import StoryBlockPage from "./pages/StoryBlockPage";
import {
  ALL_USERS,
  PREVIEW_APP_PATH,
  PREVIEW_STORY_BLOCK_PATH,
  PREVIEW_STORY_TREE_PATH,
  STORY_BLOCK_PATH,
  STORY_TREE_PATH,
  USER,
} from "./Routes";
import { useDatabase } from "./data/DatabaseContext";
import StoryTreePage from "./pages/StoryTreePage";
import StoryTreePreviewPage from "./pages/StoryTreePreviewPage";
import StoryBlockPreviewPage from "./pages/StoryBlockPreviewPage";
import UserPreviewPage from "./pages/UserPreview";

function App() {
  const { getDatabase } = useDatabase();
  console.log(getDatabase());
  return (
    <div className="App">
      <header className="App-header">
        <Switch>
          <Route path={ALL_USERS}>
            <AllUsersPage></AllUsersPage>
          </Route>
          <Route path={USER} exact>
            <UserPage></UserPage>
          </Route>
          <Route path={STORY_BLOCK_PATH} exact>
            <StoryBlockPage></StoryBlockPage>
          </Route>
          <Route path={STORY_TREE_PATH} exact>
            <StoryTreePage></StoryTreePage>
          </Route>

          <Route path={PREVIEW_STORY_TREE_PATH} exact>
            <StoryTreePreviewPage></StoryTreePreviewPage>
          </Route>
          <Route path={PREVIEW_STORY_BLOCK_PATH} exact>
            <StoryBlockPreviewPage></StoryBlockPreviewPage>
          </Route>
          <Route path={PREVIEW_APP_PATH} exact>
            <UserPreviewPage></UserPreviewPage>
          </Route>
          <Redirect to={ALL_USERS}></Redirect>
        </Switch>
      </header>
    </div>
  );
}

export default App;
