import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IEmergencyResponseType, { emergencyResponseLevel } from "../data/types/emergencyResponseType";
import { generatePath, Link, useParams } from "react-router-dom";
import { STORY_BLOCK_PATH, STORY_TREE_PATH } from "../Routes";

import GoodIcon from "@material-ui/icons/InsertEmoticon";
import FaceIcon from "@material-ui/icons/Face";
import BadIcon from "@material-ui/icons/MoodBad";
import { ChildType } from "../data/types/screenOptionType";
import AddResponseDialog from "./AddResponseDialog";
import { useDatabase } from "../data/DatabaseContext";
import IFullUserType from "../data/types/fullUserType";

const useStyles = makeStyles({
  root: {
    width: 175,
    height: 200,
    margin: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 5,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Face = ({ responseLevel }: { responseLevel: emergencyResponseLevel }) => {
  switch (responseLevel) {
    case "good":
      return (
        <div
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(0 187 66)",
          }}
        >
          <GoodIcon fontSize="inherit" color="inherit"></GoodIcon>
        </div>
      );
    case "neutral":
      return (
        <div
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(232 225 1)",
          }}
        >
          <FaceIcon fontSize="inherit" color="inherit"></FaceIcon>
        </div>
      );
    case "bad":
      return (
        <div
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(230 5 5)",
          }}
        >
          <BadIcon fontSize="inherit" color="inherit"></BadIcon>
        </div>
      );

    default:
      return null;
  }
};

interface IProps {
  emergencyResponse: IEmergencyResponseType;
  responseLevel: emergencyResponseLevel;
}

export default function EmergencyResponseCard({ emergencyResponse, responseLevel }: IProps) {
  const classes = useStyles();
  const params = useParams();

  let openLink = "";

  switch (emergencyResponse.optionType) {
    case 1:
      openLink = generatePath(STORY_BLOCK_PATH, { ...params, storyBlockId: emergencyResponse.rootId });
      break;
    case 2:
      openLink = generatePath(STORY_TREE_PATH, { ...params, storyTreeId: emergencyResponse.rootId });
      break;
    default:
      break;
  }

  return (
    <Card className={classes.root}>
      <CardContent style={{ flex: 1 }}>
        <Face responseLevel={responseLevel}></Face>

        <h3 style={{ fontSize: 13, margin: 0, marginBottom: "-15px" }}>{emergencyResponse.name}</h3>
      </CardContent>
      {!!emergencyResponse.id ? (
        <React.Fragment>
          <CardActions>
            <Button
              component={Link}
              to={openLink}
              size="small"
              variant="outlined"
              color="primary"
              style={{ width: "100%" }}
            >
              Open
            </Button>
            <AddResponseDialog responseLevel={responseLevel}>Change</AddResponseDialog>
          </CardActions>
          {/* <CardActions style={{ marginTop: "-10px" }}>
            <Button onClick={() => {}} size="small" variant="contained" color="primary" style={{ width: "100%" }}>
              Preview
            </Button>
          </CardActions> */}
        </React.Fragment>
      ) : (
        <CardActions>
          <AddResponseDialog responseLevel={responseLevel}>Add Response</AddResponseDialog>
        </CardActions>
      )}
    </Card>
  );
}
