import React from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import "overlayscrollbars/css/OverlayScrollbars.css";
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";

interface IProps {
  resetScreen?: () => void;
  children: any;
}

const PreviewContainer = ({ resetScreen, children }: IProps) => {
  const handleReset = () => resetScreen && resetScreen();

  return (
    <div
      style={{
        minWidth: 300,
        maxWidth: 400,
        width: "calc(100% - 6px)",
        flex: 1,
        minHeight: 400,
        maxHeight: 600,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "2px solid #000",
        borderRadius: 8,
        margin: 1,
        color: "#000",
        height: "100%",
      }}
    >
      <div
        style={{
          height: 48,
          width: "100%",
          backgroundColor: "#717ec3",
          textAlign: "right",
          borderRadius: "6px 6px 0px 0px",
        }}
      >
        <IconButton>
          <AutorenewIcon onClick={handleReset}></AutorenewIcon>
        </IconButton>
      </div>
      <OverlayScrollbarsComponent
        options={{ scrollbars: { autoHide: "scroll" } }}
        style={{ width: "100%", height: "100%" }}
      >
        {children}
      </OverlayScrollbarsComponent>
    </div>
  );
};

export default PreviewContainer;
