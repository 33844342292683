import React, { useState } from "react";
import create_UUID from "../../utils/create_uuid";
import { users_SeedData } from "../seedData";
import { emergencyResponseLevel } from "../types/emergencyResponseType";
import IUserType from "../types/userType";

export const DEFAULT_USER: IUserType = {
  id: "",
  name: "",
  storyBlockIds: [],
  storyTreeIds: [],
  deleted: false,
  goodResponseId: "",
  neutralResponseId: "",
  badResponseId: "",
};

export default function useUserEntity(): UserEntityType {
  const [users, setUsers] = useState<Array<IUserType>>(users_SeedData);

  const _addUsers = (user: IUserType) => {
    setUsers([...users, user]);
  };

  const _removeUser = (userId: string) => {
    const filtered_users = users.filter((sc) => sc.id !== userId);
    setUsers(filtered_users);
  };

  const fetchUser = (userId: string): IUserType => {
    const user = users.find((sc) => sc.id === userId);
    return user || DEFAULT_USER;
  };

  const fetchAllUsers = (): Array<IUserType> => {
    return users.filter((u) => !u.deleted);
  };

  const createUser = (name: string): string => {
    let user = { ...DEFAULT_USER };
    user.id = create_UUID();
    user.name = name;
    _addUsers(user);
    return user.id;
  };

  const deleteUser = (userId: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        const updatedUser: IUserType = {
          ...user,
          deleted: true,
        };

        return updatedUser;
      }

      return user;
    });

    setUsers(newUsers);
  };

  const updateName = (userId: string, name: string) => {
    const newUsers = users.map((user) => {
      if (user.id === userId) {
        const updatedUser: IUserType = {
          ...user,
          name,
        };

        return updatedUser;
      }

      return user;
    });

    setUsers(newUsers);
  };

  const addStoryBlock = (userId: string, storyBlockId: string) => {
    const newList = users.map((item) => {
      if (item.id === userId) {
        item.storyBlockIds?.push(storyBlockId);
      }

      return item;
    });

    setUsers(newList);
  };

  const removeStoryBlock = (userId: string, storyBlockId: string) => {
    const newList = users.map((item) => {
      if (item.id === userId) {
        const updatedItem: IUserType = {
          ...item,
          storyBlockIds: item.storyBlockIds?.filter((o) => o !== storyBlockId),
        };

        return updatedItem;
      }

      return item;
    });

    setUsers(newList);
  };

  const addStoryTree = (userId: string, storyTreeId: string) => {
    const newList = users.map((item) => {
      if (item.id === userId) {
        item.storyTreeIds?.push(storyTreeId);
      }

      return item;
    });

    setUsers(newList);
  };

  const removeStoryTree = (userId: string, storyTreeId: string) => {
    const newList = users.map((item) => {
      if (item.id === userId) {
        const updatedItem: IUserType = {
          ...item,
          storyTreeIds: item.storyTreeIds?.filter((o) => o !== storyTreeId),
        };

        return updatedItem;
      }

      return item;
    });

    setUsers(newList);
  };

  const addEmergencyResponse = (
    userId: string,
    emergencyResponseId: string,
    emergencyResponseLevel: emergencyResponseLevel
  ) => {
    const newList = users.map((item) => {
      if (item.id === userId) {
        switch (emergencyResponseLevel) {
          case "good":
            item.goodResponseId = emergencyResponseId;
            break;
          case "neutral":
            item.neutralResponseId = emergencyResponseId;
            break;
          case "bad":
            item.badResponseId = emergencyResponseId;
            break;

          default:
            break;
        }
      }

      return item;
    });

    setUsers(newList);
  };

  const getAll = () => users;

  return {
    getAll,
    createUser,
    deleteUser,
    updateName,
    addStoryBlock,
    removeStoryBlock,
    addStoryTree,
    removeStoryTree,
    fetchUser,
    fetchAllUsers,
    addEmergencyResponse,
  };
}

export type UserEntityType = {
  getAll: () => Array<IUserType>;
  createUser: (name: string) => string;
  deleteUser: (userId: string) => void;
  updateName: (userId: string, name: string) => void;
  addStoryBlock: (userId: string, storyBlockId: string) => void;
  removeStoryBlock: (userId: string, storyBlockId: string) => void;
  addStoryTree: (userId: string, storyTreeId: string) => void;
  removeStoryTree: (userId: string, storyTreeId: string) => void;
  fetchUser: (userId: string) => IUserType;
  fetchAllUsers: () => Array<IUserType>;
  addEmergencyResponse: (
    userId: string,
    emergencyResponseId: string,
    emergencyResponseLevel: emergencyResponseLevel
  ) => void;
};
