import React, { useEffect, useState } from "react";
import { useDatabase } from "../data/DatabaseContext";
import { makeStyles } from "@material-ui/core/styles";
import IScreenType from "../data/types/screenType";
import AddOptionDialog from "./AddOptionDialog";
import { ChildType } from "../data/types/screenOptionType";

import DeleteIcon from "@material-ui/icons/Delete";

import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import IScreenData from "../data/types/screenDataType";
import IconButton from "@material-ui/core/IconButton";
import SimpleAccordion from "./SimpleAccordion";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import "overlayscrollbars/css/OverlayScrollbars.css";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "10px 0",
    background: "#fff",
    color: "#000",
    fontSize: 18,
    height: "100%",
    overflow: "auto",
  },
  options: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
});

const styles = {
  fontSize: 18,
  width: "95%",
};

const textStyles = {
  fontSize: 18,
  flex: 1,
};

const areaStyles = {
  fontSize: 18,
  width: "95%",
  minHeight: 150,
};

const ScreenEditor = ({ screenId, children, setRequireUpdate }: any) => {
  const classes = useStyles();

  const { getScreen, addScreenOption, updateScreen, updateScreenOptionName, deleteScreenOption } = useDatabase();

  const screen: IScreenType = getScreen(screenId);

  const [name, setName] = useState("");
  const [heading, setHeading] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    setName(screen.name);
    setHeading(screen.heading);
    setText(screen.text);
  }, [screen.name, screen.heading, screen.text]);

  const onSave = (data: any) => {
    let payload: IScreenData = { name, heading, text };
    updateScreen(screenId, payload);
    setRequireUpdate();
  };

  const _addScreenOption = (parentScreenId: string, name: string, childType: ChildType, storyBlockId?: string) => {
    addScreenOption(parentScreenId, name, childType, storyBlockId);
    setRequireUpdate();
  };

  const _updateScreenOptionName = (id: string, name: string) => {
    updateScreenOptionName(id, name);
    setRequireUpdate();
  };

  const _onScreenOptionDelete = (screenOptionsId: string, parentScreenId: string) => {
    deleteScreenOption(screenOptionsId, screenId);
    setRequireUpdate();
  };

  return (
    <div className={classes.root}>
      <SimpleAccordion heading="Screen Name">
        <EditText
          name="name"
          defaultValue="Screen Name"
          value={name}
          style={styles}
          onChange={setName}
          onSave={onSave}
        />
      </SimpleAccordion>
      <SimpleAccordion heading="Heading">
        <EditText
          name="heading"
          placeholder="Heading"
          value={heading}
          style={styles}
          onChange={setHeading}
          onSave={onSave}
        />
      </SimpleAccordion>
      <SimpleAccordion heading="Description">
        <EditTextarea
          name="text"
          placeholder="Screen Description"
          value={text}
          style={areaStyles}
          onChange={setText}
          onSave={onSave}
        />
      </SimpleAccordion>
      <SimpleAccordion heading="Options">
        <div className={classes.options}>
          {screen.options?.map((o) => (
            <OptionEditor
              name={o.name}
              updateScreenOptionName={(name: string) => _updateScreenOptionName(o.id, name)}
              onDelete={() => _onScreenOptionDelete(o.id, screenId)}
            ></OptionEditor>
          ))}
          <br />
          <AddOptionDialog parentScreenId={screen.id} addScreenOption={_addScreenOption}></AddOptionDialog>
        </div>
      </SimpleAccordion>
    </div>
  );
};

export default ScreenEditor;

const OptionEditor = ({
  updateScreenOptionName,
  onDelete,
  name,
}: {
  updateScreenOptionName: (option: string) => void;
  onDelete: () => void;
  name: string;
}) => {
  const [option, setOption] = useState("");

  useEffect(() => {
    setOption(name);
  }, [name]);

  const onSave = (data: any) => {
    updateScreenOptionName(option);
  };

  return (
    <div style={{ display: "flex" }}>
      <EditText
        name="option"
        placeholder="option"
        value={option}
        style={textStyles}
        onChange={setOption}
        onSave={onSave}
      />
      <IconButton size="small" onClick={onDelete}>
        <DeleteIcon fontSize="small"></DeleteIcon>
      </IconButton>
    </div>
  );
};
