import React, { useState } from "react";
import IUserType from "../data/types/userType";
import PageWrapper from "./PageWrapper";
import UserCard from "../components/UserCard";
import { useDatabase } from "../data/DatabaseContext";
import { Button } from "@material-ui/core";
import AddUserCard from "../components/AddUser";

const UserPage = () => {
  const { state, getAllUsers, deleteUser } = useDatabase();
  const users: Array<IUserType> = getAllUsers();
  console.log(state);

  return (
    <PageWrapper heading="All Users" disableBack>
      <div style={{ padding: 40, display: "grid", gap: 10, gridTemplateColumns: "repeat(auto-fill, 200px)" }}>
        {users.map((u) => (
          <UserCard user={u} deleteUser={deleteUser}></UserCard>
        ))}
        <AddUserCard></AddUserCard>
      </div>
    </PageWrapper>
  );
};

export default UserPage;
