import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IEmergenceyResponseType from "../data/types/emergencyResponseType";
import EmergencyResponseCard from "./EmergencyResponseCard";

const useStyles = makeStyles({
  root: {},
  gridWrapper: {
    width: "100%",
    display: "flex",
    overflow: "auto",
  },
  title: {
    fontSize: 22,
    color: "#000000",
    marginBottom: 2,
    textAlign: "left",
  },
  pos: {
    marginBottom: 12,
  },
});

interface IProps {
  goodResponse: IEmergenceyResponseType;
  neutralResponse: IEmergenceyResponseType;
  badResponse: IEmergenceyResponseType;
}

export default function EmergenceyResponseList({ goodResponse, neutralResponse, badResponse }: IProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Emergency Responses</h2>
      <div className={classes.gridWrapper}>
        <EmergencyResponseCard emergencyResponse={goodResponse} responseLevel="good"></EmergencyResponseCard>
        <EmergencyResponseCard emergencyResponse={neutralResponse} responseLevel="neutral"></EmergencyResponseCard>
        <EmergencyResponseCard emergencyResponse={badResponse} responseLevel="bad"></EmergencyResponseCard>
      </div>
    </div>
  );
}
