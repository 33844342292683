import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDatabase } from "../data/DatabaseContext";
import { ChildType } from "../data/types/screenOptionType";
import { Select } from "@material-ui/core";
import { useParams } from "react-router";
import IStoryBlockType from "../data/types/storyBlockType";
import IStoryTreeType from "../data/types/storyTreeType";
import { emergencyResponseLevel } from "../data/types/emergencyResponseType";

const useStyles = makeStyles({ dialogContent: { display: "flex", padding: 5 } });

interface IData {
  name: string;
  childType: ChildType;
  rootId: string;
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: IData) => void;
  storyBlocks: Array<IStoryBlockType>;
  storyTrees: Array<IStoryTreeType>;
}

export default function AddResponseDialog({
  responseLevel,
  children,
}: {
  responseLevel: emergencyResponseLevel;
  children: any;
}) {
  const [open, setOpen] = React.useState(false);

  const params: any = useParams();

  const { getFullUser, addEmergencyResponse } = useDatabase();

  const user = getFullUser(params.userId);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data: IData) => {
    addEmergencyResponse(data.name, params.userId, responseLevel, data.childType, data.rootId);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} size="small" fullWidth>
        {children}
      </Button>
      <SimpleDialog
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        storyBlocks={user.storyBlocks}
        storyTrees={user.storyTrees}
      />
    </React.Fragment>
  );
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, onSubmit, storyBlocks, storyTrees } = props;

  const [type, setType] = useState(1);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (data: IData) => {
    onSubmit(data);
  };

  let form = <div></div>;

  switch (type) {
    case 1:
      form = (
        <StoryBlockForm
          onSubmit={handleSubmit}
          onClose={handleClose}
          storyBlocks={storyBlocks}
          type={type}
          setType={setType}
        ></StoryBlockForm>
      );
      break;
    case 2:
      form = (
        <StoryTreeForm
          onSubmit={handleSubmit}
          onClose={handleClose}
          storyTrees={storyTrees}
          type={type}
          setType={setType}
        ></StoryTreeForm>
      );
      break;
    default:
      break;
  }
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <div style={{ width: 500, height: 300, display: "flex", flexDirection: "column" }}>{form}</div>
    </Dialog>
  );
}

export function StoryBlockForm(props: {
  onSubmit: (data: IData) => void;
  onClose: () => void;
  storyBlocks: Array<IStoryBlockType>;
  type: ChildType;
  setType: (type: number) => void;
}) {
  const classes = useStyles();

  const { onSubmit, onClose, storyBlocks, type, setType } = props;

  const [name, setName] = useState("");
  const [storyBlockId, setStoryBlockId] = useState(storyBlocks[0].id);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    let data = { name, rootId: storyBlockId, childType: 1 };
    onSubmit(data);
  };

  return (
    <React.Fragment>
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogContent}>
          Add
          <div style={{ width: 12 }}></div>
          <Select
            native
            value={type}
            inputProps={{
              name: "type",
              id: "age-native-simple",
            }}
            onChange={(e: any) => setType(Number(e.target.value))}
          >
            <option value={1}>Story Block</option>
            <option value={2}>Story Tree</option>
          </Select>
          <div style={{ width: 12 }}></div>
          <Select
            native
            value={storyBlockId}
            onChange={(e: any) => setStoryBlockId(e.target.value)}
            inputProps={{
              name: "type",
              id: "age-native-simple",
            }}
          >
            {storyBlocks.map((sb: IStoryBlockType) => (
              <option value={sb.id}>{sb.name}</option>
            ))}
          </Select>
        </div>
      </DialogTitle>
      <DialogContent style={{ flex: 1 }}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Response Name"
          type="email"
          onChange={(e) => setName(e.target.value)}
          fullWidth
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!(name && storyBlockId)}>
          Add
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

export function StoryTreeForm(props: {
  onSubmit: (data: IData) => void;
  onClose: () => void;
  storyTrees: Array<IStoryTreeType>;
  type: ChildType;
  setType: (type: number) => void;
}) {
  const classes = useStyles();

  const { onSubmit, onClose, storyTrees, type, setType } = props;

  const [name, setName] = useState("");
  const [storyTreeId, setStoryTreeId] = useState(storyTrees[0].id);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    let data = { name, rootId: storyTreeId, childType: 2 };
    onSubmit(data);
  };

  return (
    <React.Fragment>
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogContent}>
          Add
          <div style={{ width: 12 }}></div>
          <Select
            native
            value={type}
            inputProps={{
              name: "type",
              id: "age-native-simple",
            }}
            onChange={(e: any) => setType(Number(e.target.value))}
          >
            <option value={1}>Story Block</option>
            <option value={2}>Story Tree</option>
          </Select>
          <div style={{ width: 12 }}></div>
          <Select
            native
            value={storyTreeId}
            onChange={(e: any) => setStoryTreeId(e.target.value)}
            inputProps={{
              name: "type",
              id: "age-native-simple",
            }}
          >
            {storyTrees.map((sb: IStoryTreeType) => (
              <option value={sb.id}>{sb.name}</option>
            ))}
          </Select>
        </div>
      </DialogTitle>
      <DialogContent style={{ flex: 1 }}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Response Name"
          type="email"
          onChange={(e) => setName(e.target.value)}
          fullWidth
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!(name && storyTreeId)}>
          Add
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
