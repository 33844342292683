import React from "react";
import { useParams } from "react-router";
import { useDatabase } from "../data/DatabaseContext";
import AddNewCard from "./AddNewCard";

const AddStoryTree = () => {
  const { addStoryTree } = useDatabase();
  const params: any = useParams();
  return <AddNewCard add={(name: string) => addStoryTree(name, params.userId)}>Create new StoryTree</AddNewCard>;
};

export default AddStoryTree;
