import React, { useState } from "react";
import PageWrapper from "./PageWrapper";
import { useDatabase } from "../data/DatabaseContext";
import IFullUserType from "../data/types/fullUserType";
import { useParams } from "react-router";
import StoryBlockList from "../components/StoryBlockList";
import StoryTreeList from "../components/StoryTreeList";
import EmergenceyResponseList from "../components/EmergenceyResponseList";

const UserPage = () => {
  const { userId }: { userId: string } = useParams();
  const { state, getFullUser } = useDatabase();
  const { user, storyBlocks, storyTrees, goodResponse, neutralResponse, badResponse }: IFullUserType = getFullUser(
    userId
  );
  console.log("user page");
  const heading = "User: " + user.name;
  return (
    <PageWrapper heading={heading}>
      <div style={{ padding: 40 }}>
        <EmergenceyResponseList
          goodResponse={goodResponse}
          neutralResponse={neutralResponse}
          badResponse={badResponse}
        ></EmergenceyResponseList>
        <StoryBlockList storyBlocks={storyBlocks}></StoryBlockList>
        <StoryTreeList storyTrees={storyTrees}></StoryTreeList>
      </div>
    </PageWrapper>
  );
};

export default UserPage;
