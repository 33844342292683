import React, { useEffect, useState } from "react";

import PreviewBottomBar from "./Preview.BottomBar";
import PreviewEmergencyScreen from "./Preview.Screen.Emergency";

import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import "overlayscrollbars/css/OverlayScrollbars.css";
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";

interface IProps {
  resetScreen?: () => void;
  setScreenId: (screenId: string) => void;
  children: any;
}
interface IProps {
  children: any;
}

const PreviewBottomBarContainer = ({ resetScreen, setScreenId, children }: IProps) => {
  const [showEmergencyScreen, setShowEmergencyScreen] = useState(false);
  const handleReset = () => resetScreen && resetScreen();

  const handleEmergencyButton = () => setShowEmergencyScreen(!showEmergencyScreen);

  const handScreenChange = (screenId: string) => {
    setShowEmergencyScreen(false);
    setScreenId(screenId);
  };

  return (
    <div
      style={{
        minWidth: 300,
        maxWidth: 360,
        width: "calc(100% - 6px)",
        flex: 1,
        minHeight: 400,
        maxHeight: 680,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "2px solid #000",
        borderRadius: 8,
        margin: 1,
        color: "#000",
        height: "100%",
      }}
    >
      <div
        style={{
          height: 48,
          width: "100%",
          backgroundColor: "#717ec3",
          textAlign: "right",
          borderRadius: "6px 6px 0px 0px",
        }}
      >
        <IconButton>
          <AutorenewIcon onClick={handleReset}></AutorenewIcon>
        </IconButton>
      </div>
      <div
        style={{
          height: "calc(100% - 78px)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <OverlayScrollbarsComponent
          options={{ scrollbars: { autoHide: "scroll" } }}
          style={{ width: "100%", height: "100%" }}
        >
          {!showEmergencyScreen ? (
            children
          ) : (
            <PreviewEmergencyScreen handleScreenChange={handScreenChange}></PreviewEmergencyScreen>
          )}
        </OverlayScrollbarsComponent>
      </div>
      <PreviewBottomBar
        resetScreen={resetScreen}
        handleEmergencyButton={handleEmergencyButton}
        isEmergencyScreen={showEmergencyScreen}
      ></PreviewBottomBar>
    </div>
  );
};
export default PreviewBottomBarContainer;
