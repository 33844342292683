import React, { useEffect, useState } from "react";
import { useDatabase } from "../data/DatabaseContext";

import "overlayscrollbars/css/OverlayScrollbars.css";
import IScreenType from "../data/types/screenType";
import PreviewOption from "./Preview.Option";
import { DEFAULT_SCREEN } from "../data/Entity/ScreenEntity";
import PreviewContainer from "./Preview.Container";
import PreviewStandardContents from "./Preview.Screen.Contents.Standard";

interface IProps {
  screenId: string;
  interactive?: boolean;
  resetScreenId?: () => void;
  setScreenId?: (screenId: string) => void;
}

const PreviewScreen = ({ screenId, interactive = false, resetScreenId, setScreenId }: IProps) => {
  const [screen, setScreen] = useState<IScreenType>(DEFAULT_SCREEN);

  const { getScreen } = useDatabase();

  useEffect(() => {
    setScreen(getScreen(screenId));
  }, [screenId, getScreen, setScreen]);

  const handleScreenChange = (screenId: string) => {
    if (interactive && setScreenId) {
      setScreenId(screenId);
    }
  };

  const handleReset = () => interactive && resetScreenId && resetScreenId();

  return (
    <PreviewContainer resetScreen={handleReset}>
      <PreviewStandardContents
        heading={screen.heading}
        text={screen.text}
        options={screen.options}
        handleScreenChange={handleScreenChange}
      ></PreviewStandardContents>
    </PreviewContainer>
  );
};

export default PreviewScreen;
