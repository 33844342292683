import React, { useState } from "react";
import create_UUID from "../../utils/create_uuid";
import { storyBlocks_SeedData } from "../seedData";
import IStoryBlockType from "../types/storyBlockType";

export const DEFAULT_STORYBLOCK: IStoryBlockType = {
  deleted: false,
  id: "",
  name: "",
  rootScreenId: "",
};

export default function useStoryBlockEntity(): StoryBlockEntityType {
  const [storyBlocks, setStoryBlock] = useState<IStoryBlockType[]>(storyBlocks_SeedData);

  const _addStoryBlock = (storyBlock: IStoryBlockType) => {
    setStoryBlock([...storyBlocks, storyBlock]);
  };

  const _removeStoryBlock = (storyblockId: string) => {
    const filtered_storyblocks = storyBlocks.filter((sb) => sb.id !== storyblockId);
    setStoryBlock(filtered_storyblocks);
  };

  const createStoryBlock = (name: string, rootScreenId: string): string => {
    let storyBlock = { ...DEFAULT_STORYBLOCK };
    storyBlock.id = create_UUID();
    storyBlock.name = name;
    storyBlock.rootScreenId = rootScreenId;
    _addStoryBlock(storyBlock);
    return storyBlock.id;
  };

  const deleteStoryBlock = (storyBlockId: string) => {
    const newList = storyBlocks.map((item) => {
      if (item.id === storyBlockId) {
        const updatedItem: IStoryBlockType = {
          ...item,
          deleted: true,
        };

        return updatedItem;
      }

      return item;
    });

    setStoryBlock(newList);
  };

  const updateName = (storyBlockId: string, name: string) => {
    const newList = storyBlocks.map((item) => {
      if (item.id === storyBlockId) {
        const updatedItem: IStoryBlockType = {
          ...item,
          name,
        };

        return updatedItem;
      }

      return item;
    });

    setStoryBlock(newList);
  };

  const fetchStoryBlock = (storyBlockId: string): IStoryBlockType => {
    const storyBlock = storyBlocks.find((sb) => sb.id === storyBlockId);
    return storyBlock || DEFAULT_STORYBLOCK;
  };

  const fetchStoryBlockBatch = (storyBlockIds: Array<string>): Array<IStoryBlockType> => {
    const filtered_storyBlocks = storyBlocks.filter((sb) => storyBlockIds.includes(sb.id) && !sb.deleted);
    return filtered_storyBlocks || [];
  };

  const getAll = () => storyBlocks;

  return { getAll, createStoryBlock, deleteStoryBlock, updateName, fetchStoryBlock, fetchStoryBlockBatch };
}

export type StoryBlockEntityType = {
  getAll: () => Array<IStoryBlockType>;
  createStoryBlock: (name: string, rootScreenId: string) => string;
  deleteStoryBlock: (id: string) => void;
  updateName: (storyBlockId: string, name: string) => void;
  fetchStoryBlock: (storyBlockId: string) => IStoryBlockType;
  fetchStoryBlockBatch: (storyBlockIds: Array<string>) => Array<IStoryBlockType>;
};
