import React, { useState } from "react";
import create_UUID from "../../utils/create_uuid";
import { storyTrees_SeedData } from "../seedData";
import IStoryTreeType from "../types/storyTreeType";

export const DEFAULT_STORYTREE: IStoryTreeType = {
  id: "",
  name: "",
  rootScreenId: "",
  deleted: false,
};

export default function useStoryTreeEntity(): StoryTreeEntityType {
  const [storyTrees, setStoryTree] = useState<IStoryTreeType[]>(storyTrees_SeedData);

  const _addStoryTree = (storyTree: IStoryTreeType) => {
    setStoryTree([...storyTrees, storyTree]);
  };

  const _removeStoryTree = (storyTreeId: string) => {
    const filtered_storyTrees = storyTrees.filter((sb) => sb.id !== storyTreeId);
    setStoryTree(filtered_storyTrees);
  };

  const createStoryTree = (name: string, rootScreenId: string): string => {
    let storyTree = DEFAULT_STORYTREE;
    storyTree.id = create_UUID();
    storyTree.name = name;
    storyTree.rootScreenId = rootScreenId;
    _addStoryTree(storyTree);
    return storyTree.id;
  };

  const deleteStoryTree = (id: string) => {
    _removeStoryTree(id);
  };

  const updateName = (storyTreeId: string, name: string) => {
    const newList = storyTrees.map((item) => {
      if (item.id === storyTreeId) {
        const updatedItem: IStoryTreeType = {
          ...item,
          name,
        };

        return updatedItem;
      }

      return item;
    });

    setStoryTree(newList);
  };

  const fetchStoryTree = (storyTreeId: string): IStoryTreeType => {
    const storyTree = storyTrees.find((sb) => sb.id === storyTreeId);
    return storyTree || DEFAULT_STORYTREE;
  };

  const fetchStoryTreeBatch = (storyTreeIds: Array<string>): Array<IStoryTreeType> => {
    const filtered_storyTrees = storyTrees.filter((st) => storyTreeIds.includes(st.id) && !st.deleted);
    return filtered_storyTrees || [];
  };

  const getAll = () => storyTrees;

  return { getAll, createStoryTree, deleteStoryTree, updateName, fetchStoryTree, fetchStoryTreeBatch };
}

export type StoryTreeEntityType = {
  getAll: () => Array<IStoryTreeType>;
  createStoryTree: (name: string, rootScreenId: string) => string;
  deleteStoryTree: (id: string) => void;
  updateName: (storyTreeId: string, name: string) => void;
  fetchStoryTree: (storyTreeId: string) => IStoryTreeType;
  fetchStoryTreeBatch: (storyTreeIds: Array<string>) => Array<IStoryTreeType>;
};
