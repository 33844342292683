import React, { useEffect, useState } from "react";
import { useDatabase } from "../data/DatabaseContext";

import "overlayscrollbars/css/OverlayScrollbars.css";
import IEmergencyResponseType, { emergencyResponseLevel } from "../data/types/emergencyResponseType";

import GoodIcon from "@material-ui/icons/InsertEmoticon";
import FaceIcon from "@material-ui/icons/Face";
import BadIcon from "@material-ui/icons/MoodBad";
import { IconButton } from "@material-ui/core";
import { useParams } from "react-router";

interface IProps {
  handleScreenChange: (screenId: string) => void;
}

const PreviewEmergencyScreen = ({ handleScreenChange }: IProps) => {
  const { getFullUser } = useDatabase();
  const { userId }: { userId: string } = useParams();
  const { goodResponse, neutralResponse, badResponse } = getFullUser(userId);
  return (
    <div
      style={{
        height: "calc(100% - 16px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 8,
        flex: 1,
      }}
    >
      <h3>How are you?</h3>

      <div>
        <IconButton
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(0 187 66)",
          }}
          onClick={() => handleScreenChange(goodResponse.rootScreenId)}
        >
          <GoodIcon fontSize="inherit" color="inherit"></GoodIcon>
        </IconButton>

        <IconButton
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(232 225 1)",
          }}
          onClick={() => handleScreenChange(neutralResponse.rootScreenId)}
        >
          <FaceIcon fontSize="inherit" color="inherit"></FaceIcon>
        </IconButton>

        <IconButton
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(230 5 5)",
          }}
          onClick={() => handleScreenChange(badResponse.rootScreenId)}
        >
          <BadIcon fontSize="inherit" color="inherit"></BadIcon>
        </IconButton>
      </div>
    </div>
  );
};

export default PreviewEmergencyScreen;

const Face = ({ responseLevel }: { responseLevel: emergencyResponseLevel }) => {
  switch (responseLevel) {
    case "good":
      return (
        <div
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(0 187 66)",
          }}
        >
          <GoodIcon fontSize="inherit" color="inherit"></GoodIcon>
        </div>
      );
    case "neutral":
      return (
        <div
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(232 225 1)",
          }}
        >
          <FaceIcon fontSize="inherit" color="inherit"></FaceIcon>
        </div>
      );
    case "bad":
      return (
        <div
          style={{
            fontSize: 86,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(230 5 5)",
          }}
        >
          <BadIcon fontSize="inherit" color="inherit"></BadIcon>
        </div>
      );

    default:
      return null;
  }
};
