import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDatabase } from "../data/DatabaseContext";
import { ChildType } from "../data/types/screenOptionType";
import { Select } from "@material-ui/core";
import { useParams } from "react-router";
import IStoryBlockType from "../data/types/storyBlockType";

const useStyles = makeStyles({ dialogContent: { display: "flex", padding: 5 } });
interface IData {
  name: string;
  childType: ChildType;
  storyBlockId: string;
}

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: IData) => void;
  allowTypes: boolean;
  storyBlocks: Array<IStoryBlockType>;
}

export default function AddOptionDialog({
  parentScreenId,
  addScreenOption,
}: {
  parentScreenId: string;
  addScreenOption: (parentScreenId: string, name: string, childType: ChildType, storyBlockId?: string) => void;
}) {
  const [open, setOpen] = React.useState(false);

  const params: any = useParams();

  const { getFullUser } = useDatabase();

  const user = getFullUser(params.userId);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data: IData) => {
    addScreenOption(parentScreenId, data.name, data.childType, data.storyBlockId);
    handleClose();
  };

  let allowTypes = !!params?.storyBlockId;

  return (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Option
      </Button>
      <SimpleDialog
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        allowTypes={allowTypes}
        storyBlocks={user.storyBlocks}
      />
    </React.Fragment>
  );
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, onSubmit, storyBlocks, allowTypes } = props;

  const [type, setType] = useState(0);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (data: IData) => {
    onSubmit(data);
  };

  let form = <div></div>;

  switch (type) {
    case 0:
      form = (
        <ScreenForm
          onSubmit={handleSubmit}
          onClose={handleClose}
          allowTypes={allowTypes}
          type={type}
          setType={setType}
        ></ScreenForm>
      );
      break;
    case 1:
      form = (
        <StoryBlockForm
          onSubmit={handleSubmit}
          onClose={handleClose}
          storyBlocks={storyBlocks}
          allowTypes={allowTypes}
          type={type}
          setType={setType}
        ></StoryBlockForm>
      );
      break;
    default:
      break;
  }
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <div style={{ width: 500, height: 300, display: "flex", flexDirection: "column" }}>{form}</div>
    </Dialog>
  );
}

export function ScreenForm(props: {
  onSubmit: (data: IData) => void;
  onClose: () => void;
  allowTypes: boolean;
  type: ChildType;
  setType: (type: number) => void;
}) {
  const classes = useStyles();
  const { onSubmit, onClose, allowTypes, type, setType } = props;

  const [name, setName] = useState("");

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    let data = { name, childType: 0, storyBlockId: "" };
    onSubmit(data);
  };
  return (
    <React.Fragment>
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogContent}>
          Add
          <div style={{ width: 12 }}></div>
          <Select
            native
            value={type}
            onChange={(e: any) => setType(Number(e.target.value))}
            inputProps={{
              name: "type",
              id: "age-native-simple",
            }}
            disabled={!!allowTypes}
          >
            <option value={0}>Screen</option>
            <option value={1}>Story Block</option>
          </Select>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Option Name"
          type="email"
          onChange={(e) => setName(e.target.value)}
          fullWidth
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!name}>
          Add
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}

export function StoryBlockForm(props: {
  onSubmit: (data: IData) => void;
  onClose: () => void;
  storyBlocks: Array<IStoryBlockType>;
  allowTypes: boolean;
  type: ChildType;
  setType: (type: number) => void;
}) {
  const classes = useStyles();

  const { onSubmit, onClose, storyBlocks, allowTypes, type, setType } = props;

  const [name, setName] = useState("");
  const [storyBlockId, setStoryBlockId] = useState(storyBlocks[0].id);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    let data = { name, storyBlockId: storyBlockId, childType: 1 };
    onSubmit(data);
  };

  return (
    <React.Fragment>
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogContent}>
          Add
          <div style={{ width: 12 }}></div>
          <Select
            native
            value={type}
            inputProps={{
              name: "type",
              id: "age-native-simple",
            }}
            onChange={(e: any) => setType(Number(e.target.value))}
            disabled={!!allowTypes}
          >
            <option value={0}>Screen</option>
            <option value={1}>Story Block</option>
          </Select>
          <div style={{ width: 12 }}></div>
          <Select
            native
            value={storyBlockId}
            onChange={(e: any) => setStoryBlockId(e.target.value)}
            inputProps={{
              name: "type",
              id: "age-native-simple",
            }}
          >
            {(storyBlocks || []).map((sb: IStoryBlockType) => (
              <option value={sb.id}>{sb.name}</option>
            ))}
          </Select>
        </div>
      </DialogTitle>
      <DialogContent style={{ flex: 1 }}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Option Name"
          type="email"
          onChange={(e) => setName(e.target.value)}
          fullWidth
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!(name && storyBlockId)}>
          Add
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
