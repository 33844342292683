import React, { useEffect, useState } from "react";
import Diagram, { createSchema, useSchema } from "beautiful-react-diagrams";
import "beautiful-react-diagrams/styles.css";
import { useDatabase } from "../data/DatabaseContext";
import { makeStyles } from "@material-ui/core";
import ScreenSidebar from "./ScreenSidebar";

const useStyles = makeStyles({
  root: {
    background: "#717EC3",
    borderRadius: "10px",
    width: 150,
    maxHeight: 100,
    fontSize: "1rem",
    transition: ".25s ease-in-out",
    "-moz-transition": ".25s ease-in-out",
    "-webkit-transition": ".25s ease-in-out",
    "&:hover": {
      opacity: 0.8,
      transition: "opacity .25s ease-in-out",
      "-moz-transition": "opacity .25s ease-in-out",
      "-webkit-transition": "opacity .25s ease-in-out",
    },
  },
  active: {
    fontWeight: "bold",
    background: "#c371b9",
    transition: ".25s ease-in-out",
    "-moz-transition": ".25s ease-in-out",
    "-webkit-transition": ".25s ease-in-out",
  },
  preview: {
    color: "#000",
    WebkitBoxShadow: "0px 0px 25px 15px rgba(45,255,196,0.8)",
    MozBoxShadow: "0px 0px 25px 15px rgba(45,255,196,0.8)",
    boxShadow: "0px 0px 25px 15px rgba(45,255,196,0.8)",
    transition: ".25s ease-in-out",
    "-moz-transition": ".25s ease-in-out",
    "-webkit-transition": ".25s ease-in-out",
  },
});

const ScreenNode = (props) => {
  const { id, content, data } = props;
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} ${data.active && classes.active} ${data.preview && classes.preview}`}
      onClick={() => data.onClick(id)}
    >
      {data.linkName && (
        <div style={{ padding: 1, paddingLeft: 7, color: "white", fontSize: 12, textAlign: "left" }}>
          Link: {data.linkName}
        </div>
      )}
      <div style={{ color: "white" }}>{content}</div>
      <div
        style={{
          color: "#000",
          padding: 1,
          paddingRight: 7,
          marginLeft: "auto",
          fontSize: 10,
          textAlign: "right",
          marginTop: "-4px",
          fontWeight: "bold",
        }}
      >
        Screen
      </div>
    </div>
  );
};

const StoryBlockNode = (props) => {
  const { id, content, data } = props;
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} ${data.active && classes.active} ${(data.active || data.preview) && classes.preview}`}
      onClick={() => data.onClick(id)}
    >
      {data.linkName && (
        <div style={{ padding: 1, paddingLeft: 7, color: "white", fontSize: 12, textAlign: "left" }}>
          Link: {data.linkName}
        </div>
      )}
      <div style={{ paddingBottom: 5, color: "white" }}>{content}</div>
      <div
        style={{
          color: "#000",
          padding: 1,
          paddingRight: 7,
          marginLeft: "auto",
          fontSize: 10,
          textAlign: "right",
          marginTop: "-4px",
          fontWeight: "bold",
        }}
      >
        Story Block
      </div>
    </div>
  );
};

const ScreenMap = ({ rootScreenId, currentScreen, changeCurrentScreen }) => {
  const [requireUpdate, setRequireUpdate] = useState(true);

  const baseX = 50;
  const baseY = 100;
  const multX = 200;
  const multY = 100;

  // create diagrams schema
  let links = [];
  let nodes = [];
  const { getScreen, getStoryBlock } = useDatabase();

  const [previewScreen, setPreviewScreen] = useState(rootScreenId);
  const [showPreview, setShowPreview] = useState(false);
  const [storyBlockId, setStoryBlockId] = useState("");

  const toggleShowPreview = () => setShowPreview(!showPreview);

  const changePreviewScreen = (screenId) => {
    // console.log(screenId);
    setPreviewScreen(screenId);
  };

  const onClick = (screenId) => {
    changeCurrentScreen(screenId);
    setPreviewScreen(screenId);
    setStoryBlockId("");
    nodes = [];
    links = [];
    createSchema(rootScreenId, 0, screenId, 0, screenId, "");
    onChange({ nodes, links });
  };

  const onStoryBlockClick = (screenId, storyBlockId) => {
    changeCurrentScreen(screenId);
    setPreviewScreen(screenId);
    setStoryBlockId(storyBlockId);
    nodes = [];
    links = [];
    createSchema(rootScreenId, 0, screenId, 0, screenId, "");
    onChange({ nodes, links });
  };

  const handlePreviewClick = (screenId) => {
    changePreviewScreen(screenId);
    nodes = [];
    links = [];
    updateCanvasPreview(screenId);
    onChange({ nodes, links });
  };

  const updateCanvas = (currentScreen) => createSchema(rootScreenId, 0, currentScreen, 0, previewScreen, "");

  const updateCanvasPreview = (previewScreen) => createSchema(rootScreenId, 0, currentScreen, 0, previewScreen, "");

  const createSchema = (screenId, depth, activeScreen, index, previewScreen, linkName) => {
    const screen = getScreen(screenId);
    let x = baseX + depth * multX;
    let y = 0;
    let leaves = 0;

    let isLeaf = !screen.options.length;

    screen.options.forEach((o, i, arr) => {
      let newLink = { input: o.parentScreenId, output: o.childScreenId };
      links.push(newLink);
      switch (o.childType) {
        case 0:
          leaves += createSchema(o.childScreenId, depth + 1, activeScreen, index + leaves, previewScreen, o.name);
          break;
        case 1:
          leaves += createSchema_StoryBlock(
            o.storyBlockId,
            depth + 1,
            activeScreen,
            index + leaves,
            previewScreen,
            o.name
          );

          break;
        default:
          break;
      }
    });

    y = isLeaf ? index * multY + baseY : index * multY + ((leaves - 1) * multY) / 2 + baseY;

    let newNode = {
      id: screen.id,
      content: screen.name,
      disableDrag: true,
      data: {
        active: screen.id === activeScreen,
        preview: showPreview && screen.id === previewScreen,
        depth,
        isLeaf,
        onClick: onClick,
        linkName,
      },
      coordinates: [x, y],
      render: ScreenNode,
    };

    nodes.push(newNode);

    return isLeaf ? 1 : leaves;
  };

  const createSchema_StoryBlock = (storyBlockId, depth, activeScreen, index, previewScreen, linkName) => {
    const storyBlock = getStoryBlock(storyBlockId);
    let x = baseX + depth * multX;
    let y = 0;

    y = index * multY + baseY;

    let newNode = {
      id: storyBlock.rootScreenId,
      content: storyBlock.name,
      disableDrag: true,
      data: {
        active: storyBlock.rootScreenId === activeScreen,
        preview: showPreview && storyBlock.rootScreenId === previewScreen,
        depth,
        isLeaf: true,
        onClick: (screenId) => onStoryBlockClick(screenId, storyBlockId),
        linkName,
      },
      coordinates: [x, y],
      render: StoryBlockNode,
    };

    nodes.push(newNode);

    return 1;
  };

  const [schema, { onChange }] = useSchema({ links, nodes });

  useEffect(() => {
    if (requireUpdate) {
      updateCanvas(currentScreen);
      onChange({ nodes, links });
      setRequireUpdate(false);
    }
  }, [requireUpdate, updateCanvas, onChange, setRequireUpdate, currentScreen]);

  useEffect(() => {
    setRequireUpdate(true);
  }, [currentScreen, showPreview]);

  return (
    <React.Fragment>
      <div style={{ height: "calc(100vh - 104px)", flex: 1, padding: 20 }}>
        <Diagram schema={schema} onChange={onChange} />
      </div>
      <ScreenSidebar
        screenId={currentScreen}
        setRequireUpdate={() => setRequireUpdate(true)}
        previewScreenId={previewScreen}
        changePreviewScreen={handlePreviewClick}
        showPreview={showPreview}
        toggleShowPreview={toggleShowPreview}
        storyBlockId={storyBlockId}
      ></ScreenSidebar>
    </React.Fragment>
  );
};

export default ScreenMap;
