import React, { useEffect, useState } from "react";
import IUserType from "../data/types/userType";
import PageWrapper from "./PageWrapper";
import { useDatabase } from "../data/DatabaseContext";
import { useParams } from "react-router";
import IStoryBlockType from "../data/types/storyBlockType";
import ScreenMap from "../components/ScreenMap";
import IScreenType from "../data/types/screenType";
import ScreenEditor from "../components/ScreenEditor";

const StoryBlockPage = () => {
  const { userId, storyBlockId }: { userId: string; storyBlockId: string } = useParams();
  const { getStoryBlock, getUser, getScreen } = useDatabase();
  const user: IUserType = getUser(userId);
  const storyBlock: IStoryBlockType = getStoryBlock(storyBlockId);
  const heading: string = "User: " + user.name + " > Story Block: " + storyBlock.name + " - edit";

  const [currentScreen, setCurrentScreen] = useState(storyBlock.rootScreenId);

  useEffect(() => {
    setCurrentScreen(storyBlock.rootScreenId);
  }, [setCurrentScreen, storyBlock.rootScreenId]);

  const changeCurrentScreen = (screenId: string) => {
    // console.log(screenId);
    setCurrentScreen(screenId);
  };

  return (
    <PageWrapper heading={heading}>
      <div style={{ display: "flex" }}>
        <ScreenMap
          rootScreenId={storyBlock.rootScreenId}
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
        ></ScreenMap>
      </div>
    </PageWrapper>
  );
};

export default StoryBlockPage;
