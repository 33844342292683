import React from "react";
import Button from "@material-ui/core/Button";
import IStoryTreeType from "../data/types/storyTreeType";

interface IProps {
  option: IStoryTreeType;
  changeScreen: (screenId: string) => void;
}

const PreviewTreeOption = ({ option, changeScreen }: IProps) => {
  const handleClick = () => {
    changeScreen(option.rootScreenId);
  };
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      size="small"
      style={{
        width: "100%",
        margin: 6,
        backgroundColor: "#009ba2",
        color: "#fff",
        height: 60,
        borderRadius: 25,
        fontSize: 16,
      }}
    >
      {option.name}
    </Button>
  );
};

export default PreviewTreeOption;
