import React from "react";

import "overlayscrollbars/css/OverlayScrollbars.css";
import PreviewOption from "./Preview.Option";
import IScreenOptionType from "../data/types/screenOptionType";

interface IProps {
  heading: string;
  text: string;
  handleScreenChange: (screenId: string) => void;
  options?: Array<IScreenOptionType>;
}

const PreviewScreen = ({ heading, text, handleScreenChange, options }: IProps) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 8,
      }}
    >
      <h3>{heading}</h3>
      <p>{text}</p>
      {options?.map((o) => (
        <PreviewOption option={o} changeScreen={handleScreenChange}></PreviewOption>
      ))}
    </div>
  );
};

export default PreviewScreen;
