import React, { useState } from "react";
import { useDatabase } from "../data/DatabaseContext";
import AddNewCard from "./AddNewCard";

const AddUserCard = () => {
  const { addUser } = useDatabase();
  return <AddNewCard add={addUser}>Create new user</AddNewCard>;
};

export default AddUserCard;
