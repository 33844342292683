import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDatabase } from "../data/DatabaseContext";
import AddNewCard from "./AddNewCard";

const AddStoryBlock = () => {
  const { addStoryBlock } = useDatabase();
  const params: any = useParams();
  return <AddNewCard add={(name: string) => addStoryBlock(name, params.userId)}>Create new StoryBlock</AddNewCard>;
};

export default AddStoryBlock;
