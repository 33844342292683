import React, { useState, useEffect } from "react";
import { useDatabase } from "../data/DatabaseContext";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

import "overlayscrollbars/css/OverlayScrollbars.css";
import PreviewStandardContents from "./Preview.Screen.Contents.Standard";
import { DEFAULT_SCREEN } from "../data/Entity/ScreenEntity";
import PreviewBottomBarContainer from "./Preview.Container.BottomBar";
import PreviewHomeContents from "./Preview.Screen.Contents.Home";

interface IProps {
  rootScreenId: string;
}

export default function PreviewApp({ rootScreenId }: IProps) {
  const [screenId, setScreenId] = useState("");
  const [screen, setScreen] = useState(DEFAULT_SCREEN);

  useEffect(() => {
    setScreenId(rootScreenId);
  }, [setScreenId, rootScreenId]);

  const { getScreen } = useDatabase();

  useEffect(() => {
    setScreen(getScreen(screenId));
  }, [screenId, getScreen, setScreen]);

  const handleScreenChange = (screenId: string) => {
    setScreenId(screenId);
  };

  const handleReset = () => setScreenId(rootScreenId);

  return (
    <PreviewBottomBarContainer resetScreen={handleReset} setScreenId={handleScreenChange}>
      {!!screenId ? (
        <PreviewStandardContents
          heading={screen.heading}
          text={screen.text}
          options={screen.options}
          handleScreenChange={handleScreenChange}
        ></PreviewStandardContents>
      ) : (
        <PreviewHomeContents handleScreenChange={handleScreenChange}></PreviewHomeContents>
      )}
    </PreviewBottomBarContainer>
  );
}
