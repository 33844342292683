export default {
  users: [
    {
      id: "4fb183c4-c8bb-473a-b64b-47a586dec4a8",
      name: "Tommy Example",
      storyBlockIds: ["cef8b1e6-8681-4ffa-807b-6b24f154d42a", "b0ae1f31-9cb1-465a-b423-6737521a0bd1"],
      storyTreeIds: ["f0ecf458-77a2-44ee-b02d-266191bce940"],
      deleted: false,
      goodResponseId: "2688b016-f939-4fba-8ba4-67d0bf3194a6",
      neutralResponseId: "",
      badResponseId: "",
    },
  ],
  screenOptions: [
    {
      id: "54f5a0a4-adbf-40b5-a7ee-bedb04a14bad",
      name: "No",
      parentScreenId: "7da9260e-def3-4a33-befb-93ffd9a55a38",
      childScreenId: "776b50e1-27b3-4731-bf6f-e1f93478b1eb",
      childType: 0,
      storyBlockId: "",
    },
    {
      id: "319018da-9aa5-4a6a-83e4-ac35ee1da0f9",
      name: "Yes",
      parentScreenId: "7da9260e-def3-4a33-befb-93ffd9a55a38",
      childScreenId: "b0752039-ff8e-4c84-a7b4-44bb91fff401",
      childType: 0,
      storyBlockId: "",
    },
    {
      id: "013d253c-3df4-4cbc-9a39-9bf5d328dd23",
      name: "I don't know",
      parentScreenId: "7da9260e-def3-4a33-befb-93ffd9a55a38",
      childScreenId: "30a1194d-bb7f-473e-b8c3-40da968e4a18",
      childType: 0,
      storyBlockId: "",
    },
    {
      id: "308ea417-2e74-4756-9ddc-a5ea72c8b0ce",
      name: "Not really",
      parentScreenId: "30a1194d-bb7f-473e-b8c3-40da968e4a18",
      childScreenId: "d70d9517-6713-43d3-8958-1beaad8b5759",
      childType: 0,
      storyBlockId: "",
    },
    {
      id: "609f451e-b86c-4e86-aa50-2b7553367c11",
      name: "I need the toilet",
      parentScreenId: "f48d5324-1f5e-4d4f-9834-1c5341721cd4",
      childScreenId: "7da9260e-def3-4a33-befb-93ffd9a55a38",
      childType: 1,
      storyBlockId: "cef8b1e6-8681-4ffa-807b-6b24f154d42a",
    },
    {
      id: "97df3322-8043-44f8-abe6-498d26976523",
      name: "I feel distracted",
      parentScreenId: "f48d5324-1f5e-4d4f-9834-1c5341721cd4",
      childScreenId: "defbca6a-f759-48e8-b6f0-ad1af4e9b7d5",
      childType: 0,
    },
    {
      id: "08ebc6b7-1709-41dc-a482-15b434d6387b",
      name: "Yes",
      parentScreenId: "d70d9517-6713-43d3-8958-1beaad8b5759",
      childScreenId: "a9212552-1057-4399-9e06-0b241a29af8d",
      childType: 0,
    },
  ],
  screens: [
    {
      id: "7da9260e-def3-4a33-befb-93ffd9a55a38",
      name: "Teacher is speaking",
      heading: "Is the teacher talking?",
      text: "",
      optionIds: [
        "54f5a0a4-adbf-40b5-a7ee-bedb04a14bad",
        "319018da-9aa5-4a6a-83e4-ac35ee1da0f9",
        "013d253c-3df4-4cbc-9a39-9bf5d328dd23",
      ],
      options: [],
      parentOptionId: "",
    },
    {
      id: "776b50e1-27b3-4731-bf6f-e1f93478b1eb",
      name: "Ask the Teacher",
      heading: "Ask the Teacher",
      text: "Put your hand up and ask to go to the toilet",
      optionIds: [],
      options: [],
      parentOptionId: "54f5a0a4-adbf-40b5-a7ee-bedb04a14bad",
    },
    {
      id: "b0752039-ff8e-4c84-a7b4-44bb91fff401",
      name: "Wait for the Teacher",
      heading: "Wait for the teacher to finish",
      text: "Wait for the teacher to finish talking, then put your hand up and ask to go to the toilet",
      optionIds: [],
      options: [],
      parentOptionId: "",
    },
    {
      id: "30a1194d-bb7f-473e-b8c3-40da968e4a18",
      name: "Something else",
      heading: "Are you desperate?",
      text: "",
      optionIds: ["308ea417-2e74-4756-9ddc-a5ea72c8b0ce"],
      options: [],
      parentOptionId: "",
    },
    {
      id: "d70d9517-6713-43d3-8958-1beaad8b5759",
      name: "Waiting",
      heading: "Can you wait until the end of class?",
      text: "",
      optionIds: [
        "19f9e8bb-6af1-40a7-847d-a896160363f8",
        "08ebc6b7-1709-41dc-a482-15b434d6387b",
        "08ebc6b7-1709-41dc-a482-15b434d6387b",
      ],
      options: [],
      parentOptionId: "",
    },
    {
      id: "f48d5324-1f5e-4d4f-9834-1c5341721cd4",
      name: "Maths root screen",
      heading: "Maths Class",
      text: "How is maths going?",
      optionIds: [
        "609f451e-b86c-4e86-aa50-2b7553367c11",
        "97df3322-8043-44f8-abe6-498d26976523",
        "97df3322-8043-44f8-abe6-498d26976523",
      ],
      options: [],
      parentOptionId: "",
    },
    {
      id: "0f3d6e57-5011-4aa2-828d-e38c84b8d6bd",
      name: "Good Response root screen",
      heading: "Well Done!",
      text: "You're doing really well, keep it up!",
      optionIds: [],
      options: [],
      parentOptionId: "",
    },
    {
      id: "defbca6a-f759-48e8-b6f0-ad1af4e9b7d5",
      name: "I feel distracted",
      heading: "Deep Breathing",
      text: "Take a moment to close your eyes and take three deep breathes, then get back to what you should be doing.",
      optionIds: [],
      options: [],
      parentOptionId: "",
    },
    {
      id: "a9212552-1057-4399-9e06-0b241a29af8d",
      name: "Yes",
      heading: "",
      text: "You're doing really well, if you decide you can't wait you can ask for help again.",
      optionIds: [],
      options: [],
      parentOptionId: "",
    },
  ],
  storyBlocks: [
    {
      deleted: false,
      id: "cef8b1e6-8681-4ffa-807b-6b24f154d42a",
      name: "Going to the toilet",
      rootScreenId: "7da9260e-def3-4a33-befb-93ffd9a55a38",
    },
    {
      deleted: false,
      id: "b0ae1f31-9cb1-465a-b423-6737521a0bd1",
      name: "Good Response",
      rootScreenId: "0f3d6e57-5011-4aa2-828d-e38c84b8d6bd",
    },
  ],
  storyTree: [
    {
      deleted: false,
      id: "f0ecf458-77a2-44ee-b02d-266191bce940",
      name: "Maths",
      rootScreenId: "f48d5324-1f5e-4d4f-9834-1c5341721cd4",
    },
  ],
  emergencyResponse: [
    {
      id: "2688b016-f939-4fba-8ba4-67d0bf3194a6",
      rootScreenId: "0f3d6e57-5011-4aa2-828d-e38c84b8d6bd",
      name: "Good Response",
      deleted: false,
      optionType: 1,
      rootId: "b0ae1f31-9cb1-465a-b423-6737521a0bd1",
    },
  ],
};
