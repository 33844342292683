import React, { useContext } from "react";
import { DEFAULT_USER } from "./Entity/UserEntity";
import useScreenEntity, { DEFAULT_SCREEN, ScreenEntityType } from "./Entity/ScreenEntity";
import useScreenOptionEntity, { DEFAULT_OPTION, ScreenOptionEntityType } from "./Entity/ScreenOptionEntity";
import useStoryBlockEntity, { DEFAULT_STORYBLOCK, StoryBlockEntityType } from "./Entity/StoryBlockEntity";
import useStoryTreeEntity, { DEFAULT_STORYTREE, StoryTreeEntityType } from "./Entity/StoryTreeEntity";
import useUserEntity, { UserEntityType } from "./Entity/UserEntity";
import IUserType from "./types/userType";
import IFullUserType from "./types/fullUserType";
import IStoryBlockType from "./types/storyBlockType";
import IScreenType from "./types/screenType";
import IDatabaseSchema from "./types/databaseSchema";
import { ChildType } from "./types/screenOptionType";
import IScreenData from "./types/screenDataType";
import IStoryTreeType from "./types/storyTreeType";
import { emergencyResponseLevel } from "./types/emergencyResponseType";
import useEmergencyResponseEntity, {
  DEFAULT_EMERGENCY_RESPONSE,
  ResponseEntityType,
} from "./Entity/EmergencyResponseEntity";

const DEFAULT_FULL_USER: IFullUserType = {
  user: DEFAULT_USER,
  storyTrees: [DEFAULT_STORYTREE],
  storyBlocks: [DEFAULT_STORYBLOCK],
  goodResponse: DEFAULT_EMERGENCY_RESPONSE,
  neutralResponse: DEFAULT_EMERGENCY_RESPONSE,
  badResponse: DEFAULT_EMERGENCY_RESPONSE,
};

const DEFAULT_DB_SCHEMA: IDatabaseSchema = {
  users: [DEFAULT_USER],
  screenOptions: [DEFAULT_OPTION],
  screens: [DEFAULT_SCREEN],
  storyBlocks: [DEFAULT_STORYBLOCK],
  storyTree: [DEFAULT_STORYTREE],
};

export const initialValues = {
  state: "unintialised",
  getAllUsers: () => {
    return [DEFAULT_USER];
  },
  addUser: (name: string) => {},
  deleteUser: (userId: string) => {},
  getUser: (useId: string) => DEFAULT_USER,
  getFullUser: (userId: string) => DEFAULT_FULL_USER,
  getStoryBlock: (storyBlockId: string) => DEFAULT_STORYBLOCK,
  getStoryBlockBatch: (storyBlockIds: Array<string>) => [DEFAULT_STORYBLOCK],
  addStoryBlock: (name: string, userId: string) => {},
  deleteStoryBlock: (storyBlockId: string) => {},
  getScreen: (screenId: string) => DEFAULT_SCREEN,
  getDatabase: () => DEFAULT_DB_SCHEMA,
  addScreenOption: (parentId: string, name: string, childType: ChildType, storyBlockId?: string) => {},
  updateScreen: (screenId: string, data: IScreenData) => {},
  updateScreenOptionName: (screenOptionId: string, name: string) => {},
  deleteScreenOption: (screenOptionId: string, screenId: string) => {},
  getStoryTree: (storyTreeId: string) => DEFAULT_STORYTREE,
  getStoryTreeBatch: (storyTreeIds: Array<string>) => [DEFAULT_STORYTREE],
  addStoryTree: (name: string, userId: string) => {},
  deleteStoryTree: (storyTreeId: string) => {},
  addEmergencyResponse: (
    name: string,
    userId: string,
    responseLevel: emergencyResponseLevel,
    optionType: ChildType,
    rootId: string
  ) => {},
};

type dbtype = typeof initialValues;

const DatabaseContext = React.createContext<dbtype>(initialValues);

export function useDatabase() {
  return useContext(DatabaseContext);
}

export default function DatabaseProvider({ children }: { children: any }) {
  const _screens: ScreenEntityType = useScreenEntity();
  const _screenOptions: ScreenOptionEntityType = useScreenOptionEntity();
  const _storyBlocks: StoryBlockEntityType = useStoryBlockEntity();
  const _storyTrees: StoryTreeEntityType = useStoryTreeEntity();
  const _users: UserEntityType = useUserEntity();
  const _responses: ResponseEntityType = useEmergencyResponseEntity();

  const getAllUsers = (): Array<IUserType> => {
    return _users.fetchAllUsers();
  };

  const getUser = (userId: string): IUserType => {
    return _users.fetchUser(userId);
  };

  const getFullUser = (userId: string): IFullUserType => {
    const user = _users.fetchUser(userId);
    const storyBlocks = _storyBlocks.fetchStoryBlockBatch(user.storyBlockIds);
    const storyTrees = _storyTrees.fetchStoryTreeBatch(user.storyTreeIds);
    const goodResponse = _responses.fetchResponse(user.goodResponseId);
    const neutralResponse = _responses.fetchResponse(user.neutralResponseId);
    const badResponse = _responses.fetchResponse(user.badResponseId);
    return { user, storyBlocks, storyTrees, goodResponse, neutralResponse, badResponse };
  };

  const addUser = (name: string) => {
    _users.createUser(name);
  };

  const deleteUser = (userId: string) => {
    _users.deleteUser(userId);
  };

  const getStoryBlock = (storyBlockId: string): IStoryBlockType => {
    return _storyBlocks.fetchStoryBlock(storyBlockId);
  };

  const getStoryBlockBatch = (storyBlockIds: Array<string>): Array<IStoryBlockType> => {
    return _storyBlocks.fetchStoryBlockBatch(storyBlockIds);
  };

  const addStoryBlock = (name: string, userId: string) => {
    const screenId = _screens.createScreen(name + " root screen");
    const storyBlockId = _storyBlocks.createStoryBlock(name, screenId);

    _users.addStoryBlock(userId, storyBlockId);
  };

  const deleteStoryBlock = (storyBlockId: string) => {
    _storyBlocks.deleteStoryBlock(storyBlockId);
  };

  const getScreen = (screenId: string): IScreenType => {
    const screen = _screens.fetchScreen(screenId);
    const options = _screenOptions.fetchScreenOptionBatch(screen.optionIds ?? []);
    screen.options = options;
    return screen;
  };

  const getDatabase = (): IDatabaseSchema => {
    let db: IDatabaseSchema = DEFAULT_DB_SCHEMA;
    db.users = _users.getAll();
    db.screenOptions = _screenOptions.getAll();
    db.screens = _screens.getAll();
    db.storyBlocks = _storyBlocks.getAll();
    db.storyTree = _storyTrees.getAll();

    return db;
  };

  const addScreenOption = (parentId: string, name: string, childType: ChildType, storyBlockId?: string) => {
    let screenOption;
    switch (childType) {
      case 0:
        const screenId = _screens.createScreen(name);
        screenOption = _screenOptions.createOption(name, parentId, screenId, childType);
        _screens.addOption(parentId, screenOption);
        break;
      case 1:
        const storyBlock = _storyBlocks.fetchStoryBlock(storyBlockId!);
        screenOption = _screenOptions.createOption(name, parentId, storyBlock.rootScreenId, childType, storyBlockId);
        _screens.addOption(parentId, screenOption);
        break;

      default:
        break;
    }
    // _screens.addParentOption()
  };

  const updateScreen = (screenId: string, data: IScreenData) => {
    _screens.updateScreenData(screenId, data);
  };

  const updateScreenOptionName = (screenOptionId: string, name: string) => {
    _screenOptions.updateName(screenOptionId, name);
  };

  const deleteScreenOption = (screenOptionId: string, screenId: string) => {
    _screens.removeOption(screenId, screenOptionId);
  };

  const getStoryTree = (storyTreeId: string): IStoryTreeType => {
    return _storyTrees.fetchStoryTree(storyTreeId);
  };

  const getStoryTreeBatch = (storyTreeIds: Array<string>): Array<IStoryTreeType> => {
    return _storyTrees.fetchStoryTreeBatch(storyTreeIds);
  };

  const addStoryTree = (name: string, userId: string) => {
    const screenId = _screens.createScreen(name + " root screen");
    const storyTreeId = _storyTrees.createStoryTree(name, screenId);
    _users.addStoryTree(userId, storyTreeId);
  };

  const deleteStoryTree = (storyTreeId: string) => {
    _storyTrees.deleteStoryTree(storyTreeId);
  };

  const addEmergencyResponse = (
    name: string,
    userId: string,
    responseLevel: emergencyResponseLevel,
    optionType: ChildType,
    rootId: string
  ) => {
    let responseId = "";
    switch (optionType) {
      case 0:
        //screen
        break;
      case 1:
        //block
        const storyBlock = _storyBlocks.fetchStoryBlock(rootId);
        responseId = _responses.createResponse(name, storyBlock.rootScreenId, optionType, rootId);
        break;
      case 2:
        //tree
        const tree = _storyTrees.fetchStoryTree(rootId);
        responseId = _responses.createResponse(name, tree.rootScreenId, optionType, rootId);
        break;
      default:
        break;
    }

    switch (responseLevel) {
      case "good":
        _users.addEmergencyResponse(userId, responseId, "good");
        break;

      case "neutral":
        _users.addEmergencyResponse(userId, responseId, "neutral");
        break;

      case "bad":
        _users.addEmergencyResponse(userId, responseId, "bad");
        break;

      default:
        break;
    }
  };

  return (
    <DatabaseContext.Provider
      value={{
        state: "initialised",
        getAllUsers,
        addUser,
        deleteUser,
        getUser,
        getFullUser,
        getStoryBlock,
        getStoryBlockBatch,
        addStoryBlock,
        deleteStoryBlock,
        getScreen,
        getDatabase,
        addScreenOption,
        updateScreen,
        updateScreenOptionName,
        deleteScreenOption,
        getStoryTree,
        getStoryTreeBatch,
        addStoryTree,
        deleteStoryTree,
        addEmergencyResponse,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
}
