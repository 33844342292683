import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IStoryTreeType from "../data/types/storyTreeType";
import { generatePath, Link, useParams } from "react-router-dom";
import { PREVIEW_STORY_TREE_PATH, STORY_TREE_PATH } from "../Routes";

const useStyles = makeStyles({
  root: {
    width: 175,
    height: 200,
    margin: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 5,
  },
  title: {
    fontSize: 14,
  },
  content: { display: "flex", flex: 1, justifyContent: "center", alignItems: "center" },
  pos: {
    marginBottom: 12,
  },
});

interface IProps {
  storyTree: IStoryTreeType;
  deleteStoryTree: (id: string) => void;
}

export default function StoryTreeCard({ storyTree, deleteStoryTree }: IProps) {
  const classes = useStyles();
  const params = useParams();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {storyTree.name}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          to={generatePath(STORY_TREE_PATH, { ...params, storyTreeId: storyTree.id })}
          size="small"
          variant="outlined"
          color="primary"
          style={{ width: "100%" }}
        >
          Open
        </Button>
        <Button
          onClick={() => deleteStoryTree(storyTree.id)}
          size="small"
          variant="outlined"
          color="primary"
          style={{ width: "100%" }}
        >
          Delete
        </Button>
      </CardActions>
      <CardActions style={{ marginTop: "-10px" }}>
        <Button
          component={Link}
          to={generatePath(PREVIEW_STORY_TREE_PATH, { ...params, storyTreeId: storyTree.id })}
          size="small"
          variant="contained"
          color="primary"
          style={{ width: "100%" }}
        >
          Preview
        </Button>
      </CardActions>
    </Card>
  );
}
