import React, { useState } from "react";
import PageWrapper from "./PageWrapper";
import { useDatabase } from "../data/DatabaseContext";
import IFullUserType from "../data/types/fullUserType";
import { useParams } from "react-router";
import PreviewApp from "../preview/Preview.App";
import IStoryBlockType from "../data/types/storyBlockType";

const StoryBlockPreviewPage = () => {
  const { userId, storyBlockId }: { userId: string; storyBlockId: string } = useParams();
  const { state, getFullUser, getStoryBlock } = useDatabase();
  const { user }: IFullUserType = getFullUser(userId);
  const storyBlock: IStoryBlockType = getStoryBlock(storyBlockId);

  const heading: string = "User: " + user.name + " > Story Block: " + storyBlock.name + " - preview";

  const tree = getStoryBlock(storyBlockId);

  return (
    <PageWrapper heading={heading}>
      <div style={{ padding: 40, height: "calc(100% - 80px)", display: "flex", justifyContent: "center" }}>
        <PreviewApp rootScreenId={tree.rootScreenId}></PreviewApp>
      </div>
    </PageWrapper>
  );
};

export default StoryBlockPreviewPage;
