import React from "react";

import "overlayscrollbars/css/OverlayScrollbars.css";
import PreviewTreeOption from "./Preview.Tree.Option";
import { useParams } from "react-router";
import { useDatabase } from "../data/DatabaseContext";
import IFullUserType from "../data/types/fullUserType";

interface IProps {
  handleScreenChange: (screenId: string) => void;
}

const PreviewHomeContents = ({ handleScreenChange }: IProps) => {
  const params: any = useParams();
  const { getFullUser } = useDatabase();

  const user: IFullUserType = getFullUser(params.userId);

  return (
    <div
      style={{
        height: "calc(100% - 16px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 8,
        flex: 1,
      }}
    >
      <h3>Select a Story Tree</h3>
      {user?.storyTrees?.map((o) => (
        <PreviewTreeOption option={o} changeScreen={handleScreenChange}></PreviewTreeOption>
      ))}
    </div>
  );
};

export default PreviewHomeContents;
