import React, { useState } from "react";
import Button from "@material-ui/core/Button";

interface IProps {
  preview: boolean;
  togglePreview: () => void;
}

const PreviewButton = ({ preview, togglePreview }: IProps) => {
  return (
    <div>
      <Button onClick={togglePreview} variant="outlined" color="primary">
        {preview ? "Edit" : "Preview"}
      </Button>
    </div>
  );
};

export default PreviewButton;
