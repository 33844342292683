import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IStoryTreeType from "../data/types/storyTreeType";
import StoryTreeCard from "./StoryTreeCard";
import { useDatabase } from "../data/DatabaseContext";
import AddStoryTree from "./AddStoryTree";

const useStyles = makeStyles({
  root: {},
  gridWrapper: {
    width: "100%",
    display: "flex",
    overflow: "auto",
  },
  title: {
    fontSize: 22,
    color: "#000000",
    marginBottom: 2,
    textAlign: "left",
  },
  pos: {
    marginBottom: 12,
  },
});

interface IProps {
  storyTrees: Array<IStoryTreeType>;
}

export default function StoryTreeList({ storyTrees }: IProps) {
  const classes = useStyles();
  const { deleteStoryTree } = useDatabase();

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Story Trees</h2>
      <div className={classes.gridWrapper}>
        {storyTrees.map((sb: IStoryTreeType) => (
          <StoryTreeCard storyTree={sb} deleteStoryTree={deleteStoryTree}></StoryTreeCard>
        ))}
        <div style={{ width: 120 }}>
          <AddStoryTree></AddStoryTree>
        </div>
      </div>
    </div>
  );
}
