import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IStoryBlockType from "../data/types/storyBlockType";
import StoryBlockCard from "./StoryBlockCard";
import { useDatabase } from "../data/DatabaseContext";
import AddStoryBlock from "./AddStoryBlock";

const useStyles = makeStyles({
  root: {},
  gridWrapper: {
    width: "100%",
    display: "flex",
    overflow: "auto",
  },
  title: {
    fontSize: 22,
    color: "#000000",
    marginBottom: 2,
    textAlign: "left",
  },
  pos: {
    marginBottom: 12,
  },
});

interface IProps {
  storyBlocks: Array<IStoryBlockType>;
}

export default function StoryBlockList({ storyBlocks }: IProps) {
  const classes = useStyles();
  const { deleteStoryBlock } = useDatabase();
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Story Blocks</h2>
      <div className={classes.gridWrapper}>
        {storyBlocks.map((sb: IStoryBlockType) => (
          <div>
            <StoryBlockCard storyBlock={sb} deleteStoryBlock={deleteStoryBlock}></StoryBlockCard>
          </div>
        ))}
        <div style={{ width: 120 }}>
          <AddStoryBlock></AddStoryBlock>
        </div>
      </div>
    </div>
  );
}
