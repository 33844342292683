import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useDatabase } from "../data/DatabaseContext";
import IScreenOptionType from "../data/types/screenOptionType";

interface IProps {
  option: IScreenOptionType;
  changeScreen: (screenId: string) => void;
}

const PreviewOption = ({ option, changeScreen }: IProps) => {
  const handleClick = () => {
    changeScreen(option.childScreenId);
  };
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      size="small"
      style={{
        width: "100%",
        margin: 6,
        backgroundColor: "#009ba2",
        color: "#fff",
        height: 60,
        borderRadius: 25,
        fontSize: 16,
      }}
    >
      {option.name}
    </Button>
  );
};

export default PreviewOption;
