import React, { useState, useEffect } from "react";

import "overlayscrollbars/css/OverlayScrollbars.css";
import { Button } from "@material-ui/core";

import HomeIcon from "@material-ui/icons/Home";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
  resetScreen?: () => void;
  handleEmergencyButton?: () => void;
  isEmergencyScreen?: boolean;
}

const size = 100;

export default function PreviewBottomBar({ resetScreen, handleEmergencyButton, isEmergencyScreen = false }: IProps) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        color: "#000",
        height: 80,
        borderTop: "2px solid #000",
        position: "relative",
      }}
    >
      <Button
        disabled={!resetScreen}
        onClick={resetScreen}
        style={{ flex: 1, borderRight: "0.5px solid #000", borderRadius: 0, fontSize: 36 }}
      >
        <HomeIcon fontSize="inherit"></HomeIcon>
      </Button>
      <Button style={{ flex: 1, borderLeft: "0.5px solid #000", borderRadius: 0, fontSize: 36 }} disabled>
        <DateRangeIcon fontSize="inherit"></DateRangeIcon>
      </Button>
      <Button
        style={{
          backgroundColor: "rgb(255 42 42)",
          position: "absolute",
          height: size,
          width: size,
          margin: 0,
          padding: 0,
          borderRadius: "50%",
          top: size / 2 - size,
          left: `calc(50% - ${size / 2}px)`,
          border: "2px solid #000",
          fontSize: 48,
        }}
        disabled={!handleEmergencyButton}
        onClick={handleEmergencyButton}
      >
        {isEmergencyScreen && <CloseIcon fontSize="inherit"></CloseIcon>}
      </Button>
    </div>
  );
}
